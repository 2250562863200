import './UnifyResults.css';

// Libraries
import { useNavigate, useParams } from 'react-router-dom';
import { useCallback, useContext, useEffect, useState } from 'react';

// Utils
import {
    GET_UNIFIED_RESULTS_FOR_TABLE,
    UNIFY_OBJECTS,
    useMutation,
    IGNORE_UNIFIED_RESULTS,
    useLazyQuery,
} from 'utils/graphql';
import { formatNumber, ucWords } from 'utils/text.util';
import { canUpdate } from 'utils/permission.util';

//Assets
import { ReactComponent as ErrorIcon } from 'assets/icons/exclamation-triangle.svg';

// Components
import ContentPanel from 'components/ContentPanel/ContentPanel';
import Loader from 'components/Loader/Loader';
import DataTable from 'components/DataTable/DataTable';
import Button from 'components/Buttons/Button/Button';
import Input from 'components/Form/Input/Input';
import { CurrentPermissionContext } from 'components/AccessWrapper/AccessWrapper';
import UnifyOrIgnoreModal from 'components/Unify/UnifyOrIgnoreModal/UnifyOrIgnoreModal';
import Modal from 'components/Modal/Modal';

const UnifyResults = () => {
    const navigate = useNavigate();
    let { table_id } = useParams();
    const { permissionSet } = useContext(CurrentPermissionContext);
    const [table, setTable] = useState();
    const [minThreshold, setMinThreshold] = useState(0);
    const [title, setTitle] = useState('Unified Results');
    const [unifiedResults, setUnifiedResults] = useState([]);
    const [isUnifyIgnoreModalOpen, setIsUnifyIgnoreModalOpen] = useState(false);
    const [unifiedResultsToMerge, setUnifiedResultsToMerge] = useState([]);
    const [infoModalType, setInfoModalType] = useState();
    const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
    const [count, setCount] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [page, setPage] = useState(1);
    const [isLoading, setIsLoading] = useState(true);

    const [getUnifiedResults] = useLazyQuery(GET_UNIFIED_RESULTS_FOR_TABLE, {
        fetchPolicy: 'cache-and-network',
        variables: {
            tableId: table_id,
            limit: perPage,
            offset: (page - 1) * perPage,
        },
        onCompleted: (data) => {
            const results = data.unifiedResultsForTable;
            setTable(results.table);
            setCount(results.count);
            setMinThreshold(results.min_threshold);
            setTitle(`Unified Results - ${ucWords(results.table.name)}`);
            const unifiedResultsOrderedAttributes = results.unified_results.map((group) => {
                return group.map((result) => {
                    const sortedAttributes = [...result.unified_result_attributes].sort((a, b) => {
                        // Sort by weight in descending order
                        if (b.weight !== a.weight) {
                            return b.weight - a.weight;
                        }
                        // If weights are the same, sort by field in ascending order
                        return a.field.localeCompare(b.field);
                    });

                    return {
                        ...result,
                        unified_result_attributes: sortedAttributes,
                    };
                });
            });
            setUnifiedResults(unifiedResultsOrderedAttributes);
            setIsLoading(false);
        },
    });

    const [unifyObjects, { loading: merging }] = useMutation(UNIFY_OBJECTS);

    const [ignoreUnifiedResult, { loading: ignoring }] = useMutation(IGNORE_UNIFIED_RESULTS);

    const showInfoModal = (type) => {
        setInfoModalType(type);
        setIsInfoModalOpen(true);
    };

    const getInfoModalOptions = () => {
        switch (infoModalType) {
            case 'mergeSuccess':
            case 'ignoreSuccess':
                return {
                    title: 'Success',
                    content: (
                        <p>
                            Unified results have been{' '}
                            {infoModalType.startsWith('merge') ? 'merged' : 'ignored'}.
                        </p>
                    ),
                    width: '250px',
                    textAlign: 'center',
                };
            case 'mergeError':
            case 'ignoreError':
                return {
                    title: <ErrorIcon fill="var(--error-color)" width="40" height="40" />,
                    content: (
                        <p>
                            There was an error while{' '}
                            {infoModalType.startsWith('merge') ? 'merging' : 'ignoring'} the unified
                            results.
                        </p>
                    ),
                    width: '250px',
                    textAlign: 'center',
                };
            default:
                return {};
        }
    };

    const merge = async (unifiedResultsIds) => {
        const { data: unifyObjectsData, error: unifyObjectsError } = await unifyObjects({
            variables: { unifiedResultsIds },
        });
        if (unifyObjectsError || !unifyObjectsData.unifyObjects?.success) {
            showInfoModal('mergeError');
        } else {
            showInfoModal('mergeSuccess');
            setTimeout(() => {
                navigate(`/unify_results/${table_id}`);
            }, 1500);
        }
    };

    const ignore = useCallback(
        async (unifiedResultsId) => {
            const { data: ignoreData, error: ignoreError } = await ignoreUnifiedResult({
                variables: { unifiedResultsId },
            });
            if (ignoreError || !ignoreData.ignoreUnifiedResults?.success) {
                showInfoModal('ignoreError');
            } else {
                showInfoModal('ignoreSuccess');
                setTimeout(() => {
                    navigate(`/unify_results/${table_id}`);
                }, 1500);
            }
        },
        [ignoreUnifiedResult, navigate, table_id]
    );

    const mergeAll = (row) => {
        merge(row.map((unifiedResult) => unifiedResult.id));
    };

    const openUnifyIgnoreModal = (row) => {
        setUnifiedResultsToMerge(row);
        setIsUnifyIgnoreModalOpen(true);
    };

    const getColumns = useCallback(() => {
        const getTooltip = (object) => {
            const sortedKeys = Object.keys(object).sort((a, b) => {
                if (a === 'id') return -1;
                if (b === 'id') return 1;
                return a.localeCompare(b);
            });
            const keyValuePairs = [];
            for (const key of sortedKeys) {
                if (keyValuePairs.length < 5) {
                    keyValuePairs.push(`${key}: ${object[key]}`);
                } else {
                    break;
                }
            }
            return keyValuePairs;
        };
        return [
            {
                name: 'Unified Attributes',
                sortable: false,
                selector: (row, index) => {
                    return (
                        <div className="attr-row" key={index}>
                            {row[0].unified_result_attributes.map((attribute, i) => (
                                <div className="attr-col" key={i}>
                                    {index === 0 && (
                                        <div className="attr-name">
                                            {attribute.field} ({attribute.weight})
                                        </div>
                                    )}
                                    <div className="attr-value">
                                        <Input value={attribute.value} readOnly={true} />
                                    </div>
                                </div>
                            ))}
                        </div>
                    );
                },
            },
            {
                name: 'Number of Matches',
                sortable: false,
                grow: 0,
                width: '160px',
                selector: (row, index) => {
                    return <div className="number-col">{formatNumber(row.length)}</div>;
                },
            },
            {
                name: 'Min Similarity Score',
                sortable: false,
                grow: 0,
                width: '150px',
                selector: (row, index) => {
                    return (
                        <div className="number-col">
                            {parseFloat(row[row.length - 1].score.toFixed(2))}
                        </div>
                    );
                },
            },
            {
                name: 'Action',
                sortable: false,
                width: '200px',
                selector: (row, index) =>
                    canUpdate(permissionSet) && (
                        <div className="action-btns">
                            <Button onClick={() => mergeAll(row)} isDisabled={merging || ignoring}>
                                Merge
                            </Button>
                            <Button
                                onClick={() => ignore(row.id)}
                                isDisabled={merging || ignoring}
                                variant="secondary"
                            >
                                Ignore
                            </Button>
                        </div>
                    ),
            },
        ];
    }, [permissionSet, table, mergeAll, ignore, merging, ignoring]);

    useEffect(() => {
        setIsLoading(true);
        window.history.replaceState(null, '', `?page=${page}&perPage=${perPage}`);
        getUnifiedResults({
            variables: {
                limit: perPage,
                offset: (page - 1) * perPage,
            },
        });
    }, [page, perPage, getUnifiedResults]);

    return (
        <div className="unify-results">
            <ContentPanel title={title}>
                <div className="unify-results-container">
                    <DataTable
                        columns={getColumns()}
                        data={unifiedResults}
                        noDataComponent="No unified results to display."
                        pagination={true}
                        paginationServer={true}
                        paginationPerPage={perPage}
                        paginationDefaultPage={page}
                        paginationTotalRows={count}
                        onChangeRowsPerPage={(newPerPage) => setPerPage(newPerPage)}
                        onChangePage={(newPage) => setPage(newPage)}
                        onRowClicked={(row) => openUnifyIgnoreModal(row)}
                        highlightOnHover={false}
                        progressPending={isLoading}
                        progressComponent={<Loader />}
                        className="unify-results-table"
                        customStyles={{
                            rows: {
                                style: {
                                    backgroundColor: 'var(--greyscale-color)',
                                },
                            },
                            table: {
                                style: {
                                    backgroundColor: 'var(--greyscale-color)',
                                },
                            },
                        }}
                    />
                </div>
            </ContentPanel>
            <UnifyOrIgnoreModal
                minThreshold={minThreshold}
                isOpen={isUnifyIgnoreModalOpen}
                setIsOpen={setIsUnifyIgnoreModalOpen}
                unifiedResults={unifiedResultsToMerge}
                table={table?.name}
                ignore={ignore}
                merge={merge}
            />
            <Modal
                isOpen={isInfoModalOpen}
                setOpen={setIsInfoModalOpen}
                options={getInfoModalOptions()}
            />
        </div>
    );
};

export default UnifyResults;
